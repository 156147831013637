<template>
  <div
    class="relative bg-cover bg-center h-230 md:h-524"
    :style="{'background-image': `url(${isLargeScreen ? bgImg : bgMobileImg})`}"
  >
    <div class="w-full absolute z-10 bottom-16 gap-1 md:(bottom-48 gap-5 flex-nowrap flex-row) flex flex-col  items-center justify-center">
      <a
        v-for="card in cards"
        class="md:(w-86 h-28) h-12 w-full"
        :href="`https://${card.url}`"
        target="_blank"
      />
    </div>
    <div class="w-full absolute z-10 bottom-3 md:bottom-12 flex justify-center">
      <a
        class="md:(w-266 h-30) h-12 w-full"
        href="https://t.me/dosan365"
        target="_blank"
      />
    </div>
  </div>
</template>
<script setup>
import bgImg from '@/assets/domain/bg.png'
import bgMobileImg from '@/assets/domain/bg-mobile.png'
import { useMediaQuery } from '@vueuse/core'

const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const cards = [
  {
    url: 'dosan-101.com'
  },
  {
    url: 'dosan-202.com'
  },
  {
    url: 't.me/+jT_fhfHoGY0wMWN'
  },
]
</script>
